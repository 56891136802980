<style>
.el-icon-info{color:#999; font-size:32px; margin-right:5px;}
.line{display:flex; flex-wrap: nowrap;}
.role{ max-width:150px; }
.subject{font-size:12px; color:#999;}
.value{font-size:12px; color:#666; }
.el-cascader{width:170px;}
.keyword{width:200px;}

.edit_dialog{height:auto; margin:15vh auto !important;}
.edit_dialog .el-row:nth-of-type(n+2){margin-top:20px;}
.edit_dialog .label{font-size:15px; color:#909399;}
.el-dialog__footer{justify-content: flex-end;}

.map_dialog{height:90%; margin:5vh auto !important;}
.map_dialog .el-dialog__body{padding:0;}
.map_dialog .address{font-size:22px; font-weight: bold; color:#666; padding-right:30px;}
#map{height:100%;}
</style>
<template>
    <div class="component">
        <!-- 编辑框 -->
        <el-dialog width="350px" custom-class="edit_dialog" :title="data_for_edit.id>0 ? '编辑本节点' : data_for_edit.prow?'添加子节点':'添加根节点'" v-model="show_edit" :close-on-click-modal="false">
            <el-row :gutter="10">
                <el-col :span="7" class="label">父节点名称</el-col>
                <el-col :span="17"><span v-html="data_for_edit.prow ? data_for_edit.prow.name_path : '-'"></span></el-col>
            </el-row>
            <el-row :gutter="10" class="label">
                <el-col :span="7">本节点名称</el-col>
                <el-col :span="17">
                    <el-input v-model="data_for_edit.name"  placeholder="输入节点名称" autocomplete="off"  size="small"></el-input>
                </el-col>
            </el-row>
            <el-row :gutter="10" class="label">
                <el-col :span="7">区划中心点</el-col>
                <el-col :span="17">
                    <el-button :type="has_location" icon="el-icon-map-location" plain size="small" @click="click_map" :disabled="true">地图选点</el-button>
                </el-col>
            </el-row>
            <template #footer>
                <el-button type="primary" @click="click_edit_done">确 定</el-button>
                <el-button @click="show_edit = false">关 闭</el-button>
            </template>
        </el-dialog>
        <!-- 编辑框结束 -->
        <!-- <el-dialog custom-class="map_dialog" width="90%" title="地图选点" v-model="show_map" :close-on-click-modal="false">
            <div id="map"></div>
            <template #footer>
                <span v-text="data_for_edit.address" class="address"></span>
                <el-button @click="show_map = false">取 消</el-button>
                <el-button type="primary" @click="click_map_done">确 定</el-button>
            </template>
        </el-dialog> -->

        <div class="title-panel">
            <el-row>
                <el-col :span="18">
                    <i class="el-icon-info"></i>
                    <div class="inline"><div class="label">行政区划管理</div><div class="descript">行政区划管理，右边按钮添加根节点。若需添加子节点，使用每行最后的添加子节点按钮。</div></div>
                </el-col>
                <el-col :span="6" class="btn-col">
                    <el-button type="success" plain icon="el-icon-circle-plus-outline" @click="click_add_root">添加根节点</el-button>
                    <el-button type="primary" plain icon="el-icon-refresh" @click="load_areas">刷新数据</el-button>
                </el-col>
            </el-row>
        </div>
        <div class="table-box" id="table-box">
            <el-table ref="table" :max-height="table_height" :data="areas" :stripe="true" row-key="id" lazy :load="lazy_load_area" border>
                <el-table-column prop="name" label="区划名称" width="250"></el-table-column>
                <el-table-column prop="name_path" label="区划路径" width="300"></el-table-column>
                <el-table-column label="排序" width="400" align="center">
                    <template #default="scope">
						<el-button size="small" plain icon="el-icon-upload2" @click="click_top(scope.row)">到顶</el-button>
						<el-button size="small" plain icon="el-icon-top" @click="click_up(scope.row)">上移</el-button>
						<el-button size="small" plain icon="el-icon-bottom" @click="click_down(scope.row)">下移</el-button>
						<el-button size="small" plain icon="el-icon-download" @click="click_bottom(scope.row)">到底</el-button>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" min-width="160" label="操作">
                    <template #default="scope">
                        <el-button @click="click_edit(scope.row)" type="text" size="small">编辑</el-button>
                        <el-button @click="click_add_child(scope.row)" type="text" size="small">添加子节点</el-button>
                        <el-button @click="click_del(scope.row)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>      
        </div>
    </div>
</template>
<script>
// import BMap from 'BMap'
export default {
  name:'Areas',
  // props:{
  //   gd:{
  //       type:Object
  //   }
  // },
  data:function(){
    return {
        areas:[],
        show_edit:false,
        data_for_edit:{},
        show_map:false,
        table_height:800,
    }
  },//data end
  mounted:function(){
    this.$nextTick(()=>{ this.table_height = document.getElementById('table-box').offsetHeight -12; });
    this.init();
  },//mounted end
  computed:{
    has_location:function(){
        return this.data_for_edit.lng && this.data_for_edit.lat ? 'success' : 'info';
    }
  },
  methods:{
    init: function(){
      this.load_areas();
    },//init
    load_areas:function(){
      this.axios.post("/api/area/list",{id:0}).then(ret=>{
        if(!ret.ok){this.$message.error(ret.msg); return;}
        this.areas = ret.data;
      });
    },//load_areas
    lazy_load_area:function(row, rowNode, resolve){
		this.axios.post("/api/area/list",{id:row.id}).then(ret=>{
			if(!ret.ok){this.$message.error(ret.msg); return;}
			ret.data.map(item=>{item.prow = row;})
			resolve(ret.data);
		});
    },//lazy_load_area
    click_top:function(row){
        this.axios.post("/api/area/top",{id:row.id}).then(ret=>{
            if(!ret.ok){this.$message.error(ret.msg); return;}
            this.$message.success('操作成功');
            if(row.pid == 0){
                this.load_depts();
            }else{
                this.$refs.table.store.states.treeData._value[row.pid].loaded = false
                this.$refs.table.store.loadOrToggle(row.prow);
            }                
        });
    },//click_top
    click_up:function(row){
        this.axios.post("/api/area/up",{id:row.id}).then(ret=>{
            if(!ret.ok){this.$message.error(ret.msg); return;}
            this.$message.success('操作成功');
            if(row.pid == 0){
                this.load_depts();
            }else{
                this.$refs.table.store.states.treeData._value[row.pid].loaded = false
                this.$refs.table.store.loadOrToggle(row.prow);
            }                
        });
    },//click_up
    click_down:function(row){
        this.axios.post("/api/area/down",{id:row.id}).then(ret=>{
            if(!ret.ok){this.$message.error(ret.msg); return;}
            this.$message.success('操作成功');
            if(row.pid == 0){
                this.load_depts();
            }else{
                this.$refs.table.store.states.treeData._value[row.pid].loaded = false
                this.$refs.table.store.loadOrToggle(row.prow);
            }                
        });
    },//click_down
    click_bottom:function(row){
        this.axios.post("/api/area/bottom",{id:row.id}).then(ret=>{
            if(!ret.ok){this.$message.error(ret.msg); return;}
            this.$message.success('操作成功');
            if(row.pid == 0){
                this.load_depts();
            }else{
                this.$refs.table.store.states.treeData._value[row.pid].loaded = false
                this.$refs.table.store.loadOrToggle(row.prow);
            }                
        });
    },//click_bottom
    click_del:function(row){
        this.$confirm('此操作将永久删除该节点, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.axios.post("/api/area/del",{id:row.id}).then(ret=>{
                if(!ret.ok){this.$message.error(ret.msg); return;}
                this.$message.success('操作成功');
                if(row.pid == 0){ this.load_areas(); }else{
                    if(this.$refs.table.store.states.treeData._value[row.pid].children.length > 1){
                        this.$refs.table.store.states.treeData._value[row.pid].loaded = false;
                        this.$refs.table.store.loadOrToggle(row.prow);
                    }else{
                        delete this.$refs.table.store.states.treeData._value[row.pid];
                        this.$refs.table.store.loadOrToggle(row.prow);
                    }
                }
            });
        }).catch((e) => { console.error(e); });
    },//click_del end
    click_add_root:function(){
        this.show_edit = true;
        this.data_for_edit = {
            pid:0,
            prow:null,
            id:0,
            name:'',
            lng:'',
            lat:'',
            address:'',
        };
    },//click_add
    click_edit:function(row){
        this.show_edit = true;
        this.data_for_edit = JSON.parse(JSON.stringify(row));
    },//click_edit end
    click_add_child:function(row){
        this.show_edit = true;
        this.data_for_edit = {
            prow:row,
            pid:row.id,
            id:0,
            name:'',
            lng:'',
            lat:'',
            address:''
        };
    },//click_add_child
    click_edit_done:function(){
		if(this.data_for_edit.name == '' ){ this.$message.error('请输入节点名称'); return; }
		let data = {id:this.data_for_edit.id, pid:this.data_for_edit.pid, name:this.data_for_edit.name, lng:this.data_for_edit.lng, lat:this.data_for_edit.lat};
		this.axios.post("/api/area/edit",data).then(ret=>{
			if(!ret.ok){this.$message.error(ret.msg); return;}
			this.$message.success('操作成功');
			this.show_edit = false;
			if(data.pid == 0){ this.load_areas(); }else{
                if(this.$refs.table.store.states.treeData._value[data.pid]){
                    this.$refs.table.store.states.treeData._value[data.pid].loaded = false;
                    if(this.$refs.table.store.states.treeData._value[data.pid].expanded){
                        this.$refs.table.store.loadOrToggle(this.data_for_edit.prow);
                    }
                }else{
                    this.data_for_edit.prow.hasChildren = 1;
                    this.$refs.table.store.loadOrToggle(this.data_for_edit.prow);
                }
			}
		});
    },//click_edit_done
    click_map:function(){
        return;
        // this.show_map = true;
        // this.$nextTick(()=>{ 
        //     this.load_map();
        // });
    },//click_map
    // click_map_done:function(){ this.show_map = false;},//click_map_done
    // load_map:function(){
    //     let map = new BMap.Map('map'); // 创建Map实例
    //     let point = null;
    //     if(this.data_for_edit.lng && this.data_for_edit.lat){
    //         point = new BMap.Point(this.data_for_edit.lng, this.data_for_edit.lat); // 创建点坐标
    //         let marker = new BMap.Marker(point);
    //         map.addOverlay(marker);
    //         this.get_address_from_geocodder(this.data_for_edit.lng, this.data_for_edit.lat);
    //     }else{
    //         point = new BMap.Point(104.065735, 30.659462); // 创建点坐标
    //     }
    //     map.centerAndZoom(point, 10);
    //     map.enableScroll
    //     map.enableScrollWheelZoom();
    //     map.addEventListener('click', e=>{
    //         map.clearOverlays();
    //         let marker = new BMap.Marker(e.point);
    //         map.addOverlay(marker);
    //         this.data_for_edit.lng = e.point.lng;
    //         this.data_for_edit.lat = e.point.lat;
    //         this.get_address_from_geocodder(e.point.lng, e.point.lat);
    //     });
    // },//load_map
    // get_address_from_geocodder:function(lng,lat){
    //     let geocoder = new BMap.Geocoder();
    //     geocoder.getLocation(new BMap.Point(lng, lat), res=>{ 
    //         //this.$set(this.data_for_edit,'address', res.address); 
    //         this.data_for_edit.address = res.address;
    //     });
    // },//get_address_from_geocodder
  },//methods end
};
</script>